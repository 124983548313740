.tip-info-card {
    color: magenta; /* Adjust the color as per your scheme */
    border-color: magenta; /* Adjust the border color */
  }
  
  .tip-info-title {
    color: magenta; /* Title color */
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(50%) sepia(100%) saturate(300%) hue-rotate(300deg) brightness(100%) contrast(100%);
    /* Adjust the color filter to match your scheme */
  }
  
  .carousel-indicators li {
    background-color: magenta; /* Dots color */
  }
  
  .carousel-indicators {
    bottom: -25px; /* Adjust the position of dots */
  }
  